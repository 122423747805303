import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.

import { device, breakpoints } from "../../utils";






const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;


const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-: 2px 5px 6px #efe8de;
      }

      &::before {
        box-: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function HyalContentfaq({setIsModalOpen}) {
    return (
      <Section py={4} id="faq" bg="#ffffff" className="pb-md-5 mb pt-5 mt-2 pt-md-5">
        <Container className="pb-md-5 mb pt-md-5">
          <Row className="justify-content-center">
            <Col lg="12">

              <Iwrap>
                <Itext as="h2">Questions about <span>Seventy Hyal 2000</span></Itext>
              </Iwrap>
              <SecondText>
                Frequently Asked Questions

              </SecondText>

            </Col>
          </Row>


          <Row data-aos="fade-up"
               data-aos-duration="750"
               data-aos-once="false"
               data-aos-delay="300" className="pt-5">
            <Col

            >


              <Accordion allowZeroExpanded>
                <AccordionItem className='blanking'>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      What to expect from the procedure?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>


                      Consultation: You will meet with one of our practitioners prior to treatment to discuss your
                      goals, assess your skin condition, and determine if you are a suitable candidate for the
                      procedure. <br /> <br />


                      Preparation: Before the procedure, the treatment area will be cleansed, and numbing cream will be
                      applied to minimise discomfort during the injections.<br /> <br />


                      Injection: This is then injected into specific areas of the face using a fine needle. The injector
                      will strategically place the skin booster to help treat the physiological depletion of hyaluronic
                      acid (HA) within the skin. The supplementation of hyaluronic acid (HA) delivers moisture-surging
                      hydration whilst simultaneously stimulating the production of new collagen and elastin.<br />
                      <br />


                      After the injections, Small, localised bumps may appear at the injection points as expected with
                      any injectable procedure.These small bumps can last anywhere from 1 hour to 48 hours before
                      dispersing under the skin.<br /> <br />


                      What to expect :Bruising is the most common side effect and usually shows immediately and will
                      last for 3-5 days. There can also be some mild swelling and tenderness. This is all completely
                      normal and generally reduces within a few days to a week. No recovery time is required, and you
                      can return to your daily activities immediately. <br /><br />

                      The longevity of results can vary between individuals however Results are expected to show almost
                      immediately with full results showing two weeks post-treatment. 2-3 sessions of the treatment
                      depending on initial skin quality spaced four weeks apart may be recommended to maintain the
                      desired results.Treatments will then need to be topped up every six to 12 months.

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion> <br />

              <Accordion allowZeroExpanded>
                <AccordionItem className='blanking'>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      Post=treatment care Seventy hHal 2000?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      <i>Ensure your hands are clean and sanitised before touching your face. </i>
                      -Do not apply any skincare products for 24 hours <br /><br />

                      -Do not apply makeup for 24 hours.<br /><br />
                      -Avoid exercise / sweating for 24 hours<br /><br />

                      -Avoid swimming, sauna’s and spa environments for 48 hours.<br /><br />

                      -Golden Rule: Make sure that you are also avoiding direct sun exposure. Especially tanning beds.
                      (if you must, please wait 7 days)<br /><br />

                      -Stay hydrated <br /><br />

                      You'll receive instructions on how to care for your skin straight after the treatment as well as
                      in our post treatment email

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion> <br />


              <Accordion allowZeroExpanded>
                <AccordionItem className='blanking'>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      Why choose Seventy Hyal?


                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Skin booster that is intended to improve hydration levels within the skin for a luminous-looking
                      complexion. Simultaneously, this skin booster stimulates the production of new collagen and
                      elastin. Ultimately, Seventy Hyal 2000 aims to inject a radiant glow into the skin, creating a
                      youthful-looking appearance. <br /><br />


                      Minimal risk: While all procedures carry some level of risk, the Seventy Hyal procedure is
                      generally considered safe when performed by a qualified and experienced practitioner. Serious
                      complications are rare, and side effects are usually mild and temporary, such as swelling or
                      bruising. <br /><br />


                      Overall, the Seventy Hyal procedure offers a less invasive and more versatile option for facial
                      rejuvenation compared to traditional surgical procedures, making it an appealing choice for many
                      individuals seeking cosmetic enhancement.

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion> <br />


              <Accordion allowZeroExpanded>
                <AccordionItem className='blanking'>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      What areas can be treated by seventy hyal 2000?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Seventy Hyal 2000 proves highly effective for enhancing the face, neck, decolletage, and hands.
                      The face is the primary focus, with strategic injections to maximize diffusion in the cheeks and
                      lower face. This approach aims to achieve optimal results while minimizing discomfort and ensuring
                      safety.

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion> <br />


              <Accordion allowZeroExpanded className="">
                <AccordionItem>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      What can be achieved with seventy hyal 2000? </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      patients can anticipate an improvement in skin firmness, elasticity, hydration, and overall skin
                      quality, with the effects lasting for a period of 6 months. The duration of these results is
                      influenced by the inherent quality of the skin, along with factors such as the patient's age and
                      lifestyle.


                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion>

              <br />


              <Accordion allowZeroExpanded className="">

                <AccordionItem>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      Is seventy hyal 2000 painful? </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Patients typically report minimal and easily manageable discomfort. We recognize that pain
                      tolerance varies among individuals, and we are pleased to offer anesthetic cream to patients
                      before commencing the treatment.

                      <br /> <br />
                      It's normal to experience slight discomfort after the procedure, but significant pain is not
                      anticipated. We will provide post-treatment guidance, and a comprehensive summary will be sent
                      directly to your email. This ensures that patients are well-informed about what to expect and the
                      recommended aftercare measures to follow.

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion>


              <br />


              <Accordion allowZeroExpanded className="">

                <AccordionItem>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      What happens on the day?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The session commences with a comprehensive, personalized consultation, encompassing an evaluation
                      of your skin, medical history, and treatment expectations. Subsequently, a customized treatment
                      plan is devised based on the assessment, outlining what to anticipate both during and after the
                      procedure.


                      Once deemed suitable for the treatment, the procedure commences. If necessary or upon request,
                      anesthetic cream can be applied. Following the disinfection of the treatment area, injection
                      points are marked, and Seventy Hyal 2000 is administered slowly and gradually.


                      After the product is injected, a gentle massage is performed to ensure the smoothness of the
                      treated area. Comprehensive post-treatment guidance will be provided, with a copy sent directly to
                      your email.


                      The entire session, including consultation time, typically takes 15-20 minutes.

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion>

              <br />

              <Accordion allowZeroExpanded className="">
                <AccordionItem>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      What filler, facials can be done with seventy hyal 2000 on the day?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Dermal filler procedures: Since Seventy Hyal 2000 is administered to areas around the cheeks,
                      under the eyes, jaws, and chin, these regions cannot undergo additional filler treatments on the
                      same day. It is necessary to allow the hyaluronic acid from the skin booster to diffuse and settle
                      before introducing further treatments. However, Non-Surgical Rhinoplasty, Lip Filler, and Smile
                      line treatments can proceed on the same day without affecting the Profhilo treatment.


                      Anti-wrinkle treatments: Patients can proceed with anti-wrinkle treatments concurrently with
                      Profhilo on the same day. Masseter treatment, however, should be scheduled either 2 weeks before
                      or after Seventy Hyal 2000 but not on the same day.


                      Facials: Vampire Facial, Microneedling, and Mesotherapy are best scheduled 2 weeks before or after
                      Seventy Hyal 2000, and it is advisable to avoid these procedures on the same day as the Seventy
                      Hyal 2000 treatment.

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion>
              <br />
              <Accordion allowZeroExpanded>

                <AccordionItem>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      Is seventy hyal 2000 safe? </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Seventy Hyal 2000 comprises a remarkably pure form of hyaluronic acid, known for its exceptional
                      biocompatibility. It's worth noting that hyaluronic acid is naturally present in our skin,
                      rendering the substance inherently safe for use in treatments.

                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion>
              <br />


              <Accordion allowZeroExpanded className="">

                <AccordionItem>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      Is there downtime/ recovery time after seventy hyal 2000 ?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The downtime or recovery time after Seventy Hyal 2000 injections is generally minimal. Many
                      individuals can resume their normal activities almost immediately after the treatment. However,
                      it's essential to note that individual responses to the injections can vary. Some ]may experience
                      mild swelling, redness, or bruising at the injection sites, but these effects are usually
                      temporary and resolve within a short period. To minimize the risk of side effects and optimize the
                      recovery process, it's advisable to follow any post-treatment instructions provided by your
                      healthcare provider.


                    </p> <br />

                  </AccordionItemPanel>
                </AccordionItem>

              </Accordion>

              <br />
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                     <AccordionItemButton className="text-[14px] pl-2">
                      Is seventy hyal 2000 worth it?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Upon injection, Profhilo disperses and creates a hyaluronic acid blanket within the dermal layer
                      of your skin. This contributes to:

                      - Enhanced Hydration
                      - Increased Plumpness
                      - Improved Tightness
                      - Augmented Firmness
                      - Softer Texture

                      The unique aspect of Profhilo lies in its ability to rejuvenate the skin without altering facial
                      structure, resulting in a more youthful, plump, tight, and firm appearance.


                    </p>
                  </AccordionItemPanel>
                </AccordionItem>


              </Accordion>


            </Col>


          </Row>


          <br />
          <div onClick={() => setIsModalOpen(true)}
               activeClassName="active"
          >
            <Bookbutton>
              Book Appointment
            </Bookbutton>
          </div>


        </Container>
      </Section>


    );
}